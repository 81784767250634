exports.components = {
  "component---src-components-blog-view-js": () => import("./../../../src/components/blogView.js" /* webpackChunkName: "component---src-components-blog-view-js" */),
  "component---src-components-recent-read-view-js": () => import("./../../../src/components/recentReadView.js" /* webpackChunkName: "component---src-components-recent-read-view-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recent-reads-js": () => import("./../../../src/pages/recent-reads.js" /* webpackChunkName: "component---src-pages-recent-reads-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

